import React from 'react'

const About = () => {
    return (
        <div>
            <h1>About: Pokedexer v.1.0.0</h1>
            <p>This is a react application utilizing the pokeAPI. </p>
            <p>The purpose of this project is for learning purposes. </p>
        </div>
    )
}

export default About
