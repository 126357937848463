export const GET_POKEMON = "GET_POKEMON";
export const SET_LOADING = "SET_LOADING";
export const GET_SPRITE = "SET_SPRITE";
export const GET_DEXENTRY = "GET_DEXENTRY";
export const GET_POKEMON_NAME = "GET_POKEMON_NAME";
export const GET_TYPES = "GET_TYPES";
export const SEARCH_FAIL = "SEARCH_FAIL";
export const GET_EVOLUTIONS = "GET_EVOLUTIONS";
export const SERVE_EVOLUTIONS = "SERVE_EVOLUTIONS";
export const SAVE_API = "SAVE_API";
export const HAVE_EVOLUTION = "HAVE_EVOLUTION";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REVERT = "REVERT";
export const STORE_EVOLUTIONS = "STORE_EVOLUTIONS";
export const STORE_2ND_EVO = "STORE_2ND_EVO";
export const CLEAR = "CLEAR";
export const EVO_SPRITE_2 = "EVO_SPRITE_2";
export const EVO_SPRITE = "EVO_SPRITE";
export const PRE_EVO = "PRE_EVO";
export const PRE_EVO_NAME = "PRE_EVO_NAME";
export const PREVIOUS_POKE = "PREVIOUS_POKE";
export const NEXT_POKE = "NEXT_POKE";
export const NEXT_PAGE_SPRITE = "NEXT_POKE";
export const PREV_PAGE_SPRITE = "PREV_PAGE_SPRITE";
export const STACK_SPRITE = "STACK_SPRITE";
